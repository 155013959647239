import React, { useEffect, useState, useMemo, useRef } from 'react'
import { useNavigate, useOutletContext, Link } from 'react-router-dom'
import { format } from 'date-fns'
import {
  utils,
  accounts,
  currency,
  entityStatus,
} from '@decision-sciences/qontrol-common'

import { useStore } from 'store'
import { useSimulationResults } from 'modules/companies/simulations'

/* Components */
import InputText from 'components/input'
import Button from 'components/button'
import { Dropdown } from 'components/dropdown'
import Modal from 'components/modal'
import Loader from 'components/loader'
import Spacer from 'components/spacer'
import useLeaveConfirm from 'components/leave-confirm'
import LabelWrapper from 'components/label-wrapper'
import Table from 'components/table/beta'
import StickyFooter from 'components/sticky-footer'
import SaveConventionModal from 'modules/naming-conventions/components/save-convention-modal'
import SaveOfflineDataModal from 'modules/offline-data/components/save-convention-modal'
import { getTooltipList } from 'components/utils/tooltip'
import { DEFAULT_DATE_FORMAT } from 'components/utils/date'

/* Other Sections */
import AlertsThresholdModal from 'modules/alerts/alerts-thresholds-statistics/simulation/simulation-modal'
// import NamingConventionsSection from 'modules/companies/subsections/naming-conventions-section'
import AssignUserModal from 'modules/companies/assign-user-modal'
import KpiSection from 'modules/companies/subsections/kpi-section/index'
// import OfflineDataSection from 'modules/companies/subsections/offline-data-section'
import CampaignExclusionsSection from 'modules/companies/subsections/campaign-exclusions-section'
import BudgetPacingSection from 'modules/companies/subsections/budget-pacing'
import AccountLead from 'modules/companies/subsections/account-lead'
// import ConversionGroupingSection from 'modules/companies/subsections/conversion-grouping/index'
// import CampaignComparisonSection from 'modules/companies/subsections/campaign-comparison'
// import ReportingViewsSection from 'modules/companies/subsections/reporting-views'
// import ReportsSection from 'modules/companies/subsections/reports'
import { RestoreNotification } from 'modules/companies/restore-popover'
import AlertThresholdSection from 'modules/companies/subsections/alert-thresholds/index'

/* Actions */
import {
  resetCachedData,
  changeInactiveCampaignsFlag,
  // changeInactiveCampaignsForComparisonFlag,
  checkForRestore,
  // undoCopySettings,
  // resetCopySettings,
  // initCopySettingsSection,
  // undoCopySettingsSection,
  checkExistingClientId,
  checkExistingName,
} from 'modules/companies/actions'
import { getAllAlertThresholdsWithActiveAlerts } from 'modules/alert-thresholds/actions'
import { getPossibleApprovers } from 'modules/users/actions'
import {
  getCustomConversionsForAccounts,
  setCustomConversionsForAccounts,
} from 'modules/accounts/actions'

/* Utils */
import {
  FIELD_TYPES,
  isValidEmail,
  isValidPhoneNumber,
  validate,
} from 'components/validator'
import { useAccess, PERMISSION_TYPES, PERMISSIONS } from 'hooks/access'
import { checkForChanges } from 'modules/naming-conventions/utils'
import {
  shouldDisplayOfflineDataDifferenceModal,
  validateOfflineData,
} from 'modules/offline-data/utils'
import { getFiscalErrors } from 'modules/companies/subsections/reporting/utils'

/* Constants */
import { ACCOUNT_TYPES } from 'modules/accounts/common'
import { TABLE_CONTAINER } from 'modules/table-filter-sort/constants'
// import {
//   COPY_SETTINGS_STATUS,
//   COMPANY_SECTIONS,
// } from 'modules/companies/constants'

import AlertsSection from 'modules/companies/subsections/alerts/index'
// import CalculatedMetricsSection from 'modules/companies/subsections/calculated-metrics/index'
import {
  useRestoreClient,
  useSeeArchivedClients,
} from 'modules/companies/hooks'
import { RemovableKpiList } from 'modules/companies/subsections/kpi-section/kpi-list/removable-list'
import { getConfigByClient } from 'modules/config/actions'
import { AccountIcon } from 'components/account-icon'
import { CheckboxNoHooks } from 'components/checkbox'
import CollapsibleSection from 'components/collapsible-section'
import { ReactComponent as ArrowsIcon } from 'assets/icon_menu_arrows.svg'
import { ReactComponent as BlueLinkIcon } from 'assets/icon_link_blue.svg'
// import { ReactComponent as UndoIcon } from 'assets/icon_undo.svg'
import { ReactComponent as PlusIcon } from 'assets/icon_plus_blue.svg'

/* Styles */
import './style.scss'

const { ACCOUNT_TYPES_MAP } = accounts
const { CURRENCY_LABELS } = currency
const { isEmpty } = utils.object
const { objectDifference } = utils.array
const { ENTITY_STATUS_LABEL, ENTITY_STATUS_OPTIONS } = entityStatus

const {
  AMAZON_DSP,
  AMAZON_ADVERTISING,
  GOOGLE,
  FACEBOOK,
  MICROSOFT,
  TRADE_DESK,
  LINKED_IN,
  TIKTOK,
  GOOGLE_ANALYTICS,
  SA360,
  GOOGLE_CAMPAIGN_MANAGER,
  APPLE,
  TWITTER,
  DV360,
  REDDIT,
  SNAPCHAT,
  PINTEREST,
} = ACCOUNT_TYPES_MAP

const CreateEditBusinessUnit = () => {
  const tableContainer = TABLE_CONTAINER.CLIENT_MANAGEMENT
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({ contact: {} })
  const [usersToEdit, setUsersToEdit] = useState([])
  const [assignUserModal, setAssignUsersModal] = useState(false)
  const { state, dispatch } = useStore()
  const [deleteUsersModal, setDeleteUsersModal] = useState(null)
  const [itemToDelete, setItemToDelete] = useState(null)
  const [setDirty, LeaveConfirmModal, dirty] = useLeaveConfirm({
    safeRoutes: ['/company/:clientId/business-unit/:businessUnitId/account'],
  })
  const [conventionNameModal, setConventionNameModal] = useState(false)
  const [offlineDataModal, setOfflineDataModal] = useState(false)
  const [allowedApprovers, setAllowedApprovers] = useState(null)
  const [restoreInfo, setRestoreInfo] = useState(null)
  const [kpis, setKpis] = useState([])
  const [loadingKpis, setLoadingKpis] = useState(0)
  // eslint-disable-next-line no-unused-vars
  const [thresholdModalAlert, setThresholdModalAlert] = useState({})
  const [showAssignAccountsModal, setShowAssignAccountsModal] = useState(false)
  const [isAccountsPanelCollapsed, setIsAccountsPanelCollapsed] =
    useState(false)
  const [assignAccounts, setAssignAccounts] = useState([])
  const [accounts, setAccounts] = useState([])
  const [accountsToEdit, setAccountsToEdit] = useState([])
  const [googleMccId, setGoogleMccId] = useState('')
  // const [copyFrom, setCopyFrom] = useState({})

  const [openedSubSection, setOpenedSubSection] = useState({
    users: true,
    reporting: true,
    thresholds: true,
    namingConventions: false,
  })
  const [alertThresholdsLoading, setAlertThresholdsLoading] = useState(false)

  const {
    company,
    setCompany,
    defaultCompany,
    isViewMode,
    linkClientSections,
    checkForSingleClientAlerts,
    businessUnitData,
    currentBusinessUnitData,
    usersData,
  } = useOutletContext()

  const [restoreClient] = useRestoreClient({
    tableContainer,
    restoreInfo,
    onSuccess: () => closeRestoreNotification(),
    onError: () => setDirty(true),
  })

  const seeArchivedClients = useSeeArchivedClients({
    tableContainer,
    setDirty,
    closeRestoreNotification: () => closeRestoreNotification(),
  })

  const { list: allUsers } = state.users
  const { active: alertThresholds } = state.alertThresholds
  const { userData } = state.session
  // const { copySettings } = state.companies
  // const copyAlertsSection = copySettings[COMPANY_SECTIONS.ALERTS]

  useEffect(() => {
    getConfigByClient(company.clientId).then((res) => {
      const googleMccId = res?.google?.manager_account_id
      if (googleMccId) {
        setGoogleMccId(googleMccId)
      }
    })
    // resetCopySettings(dispatch)
  }, [])

  const {
    businessUnit,
    setBusinessUnit,
    defaultBusinessUnitData,
    setDefaultBu,
    pendingBusinessUnitData,
    reset,
    userList,
    pendingBusinessUnitUsers,
    users,
    setUsers,
    idParam,
    clientIdParam,
  } = currentBusinessUnitData

  const initialConventions = useRef(defaultBusinessUnitData?.namingConventions)
  const initialOfflineData = useRef(defaultBusinessUnitData?.offlineData)

  const simulations = useSimulationResults({
    client: company,
    onSimulationResults: (simulationResults) =>
      setBusinessUnit({ simulationResults }),
    setLatestSimulation: (simulationResults) => {
      setDefaultBu({ ...defaultBusinessUnitData, simulationResults })
    },
    thresholdChanges: pendingBusinessUnitData.alertThresholds,
    revertThresholds: () => {
      reset(defaultBusinessUnitData, 'alertThresholds')
    },
  })

  // const [copyFromCompany, setCopyFromCompany] = useState()

  /** On mount: Fetch alert thresholds */
  useEffect(() => {
    if (!businessUnit.new) {
      setAlertThresholdsLoading(true)

      getAllAlertThresholdsWithActiveAlerts(dispatch, businessUnit._id).finally(
        () => setAlertThresholdsLoading(false)
      )
    }
  }, [businessUnit._id])

  useEffect(() => {
    if (!allowedApprovers) {
      getPossibleApprovers(businessUnit._id).then(
        (res) => res.list && setAllowedApprovers(res.list)
      )
    }
    if (businessUnit.accounts.length > 0) {
      setAccounts(businessUnit.accounts)
    }
  }, [])

  const businessUnitReadAccess = useAccess({
    feature: PERMISSIONS.BUSINESS_UNIT_DATA_FORM,
    type: PERMISSION_TYPES.READ,
  })
  const businessUnitEditAccess = useAccess({
    feature: PERMISSIONS.BUSINESS_UNIT_DATA_FORM,
    type: PERMISSION_TYPES.EDIT,
  })
  const businessUnitDeleteAccess = useAccess({
    feature: PERMISSIONS.BUSINESS_UNIT_DATA_FORM,
    type: PERMISSION_TYPES.DELETE,
  })
  const assignUsersReadAccess = useAccess({
    feature: PERMISSIONS.ASSIGN_USERS_TEAMS_BUSINESS_UNIT,
    type: PERMISSION_TYPES.READ,
  })
  const assignUsersEditAccess = useAccess({
    feature: PERMISSIONS.ASSIGN_USERS_TEAMS_BUSINESS_UNIT,
    type: PERMISSION_TYPES.EDIT,
  })
  const userDataFormEdit = useAccess({
    feature: PERMISSIONS.USER_DATA_FORM,
    type: PERMISSION_TYPES.EDIT,
  })
  const permissionGroupReadAccess = useAccess({
    feature: PERMISSIONS.PERMISSION_GROUPS,
    type: PERMISSION_TYPES.READ,
  })
  const hasEditUserAccess = userDataFormEdit && permissionGroupReadAccess

  const alertThresholdAccess = useAccess({
    feature: PERMISSIONS.ALERT_THRESHOLDS,
    type: PERMISSION_TYPES.READ,
  })
  const alertsAccess = useAccess({
    feature: PERMISSIONS.ASSIGNED_ALERTS,
    type: PERMISSION_TYPES.READ,
  })
  const budgetPacingReadAccess = useAccess({
    feature: PERMISSIONS.BUDGET_PACING,
    type: PERMISSION_TYPES.READ,
  })
  // const hasReportViewAccess = useAccess({
  //   feature: PERMISSIONS.REPORTS,
  //   type: PERMISSION_TYPES.READ,
  // })

  const readOnly = businessUnitReadAccess && !businessUnitEditAccess

  const baseClientUrl = `/company/${clientIdParam}`

  // Force user out if no read access
  useEffect(() => {
    if (!businessUnitReadAccess) {
      navigate(baseClientUrl, {
        state: { BYPASS_LEAVE_CONFIRM: true },
      })
    }
  }, [businessUnitReadAccess])

  // useEffect(() => {
  //   if (copyAlertsSection?.status === COPY_SETTINGS_STATUS.REVERTING) {
  //     if (copyAlertsSection?.currentIndex >= 0) {
  //       setCopyFromCompany(
  //         copyAlertsSection.history[copyAlertsSection.currentIndex].data
  //       )
  //       undoCopySettingsSection(dispatch, COMPANY_SECTIONS.ALERTS)
  //     }
  //   }
  // }, [JSON.stringify(copyAlertsSection)])

  const usersInClient = usersData.getClientUsers(company._id)

  const resetData = () => {
    resetCachedData(dispatch, company)
  }

  const usersTable = useMemo(() => {
    if (!assignUsersReadAccess) {
      return null
    }
    const accountLeadUser = allUsers.find(
      ({ _id }) => _id === businessUnit.accountLead
    )

    const columns = [
      {
        header: 'Name',
        accessorFn: (row) => row.name,
        textAlign: 'left',
        cellTextAlign: 'left',
        sortingFn: 'name',
      },
      {
        header: 'Email',
        accessorFn: (row) => row.email,
        textAlign: 'left',
        cellTextAlign: 'left',
      },
      {
        header: 'Teams',
        textAlign: 'left',
        cellTextAlign: 'left',
        accessorFn: (row) => {
          if (!row.teams.length) {
            return 'No Teams'
          }
          if (row.teams.length === 1) {
            return row.teams[0].name
          }
          return 'Multiple'
        },
        tooltip: (row) => {
          if (row.teams.length <= 1) {
            return null
          }
          return getTooltipList(
            'Teams',
            row.teams.map((item) => item.name)
          )
        },
      },
      {
        header: 'Last Login',
        accessorFn: (row) =>
          row.lastLogin
            ? format(new Date(row.lastLogin), DEFAULT_DATE_FORMAT)
            : ' - ',
      },
      {
        Header: 'Status',
        id: 'status',
        cell: (cell) => {
          const { active } = cell.row.original
          return <span>{ENTITY_STATUS_LABEL[active]}</span>
        },
        accessorFn: (row) => (row.active ? 1 : 0),
      },
    ]
    if (hasEditUserAccess) {
      columns.unshift({
        header: ' ',
        accessorKey: 'id',
        cell: (cell) => {
          const { _id } = cell.row.original
          return (
            <div className="table__actions align-center">
              <div
                className="table__edit "
                onClick={() => navigate(`/users/${_id}`)}
              />
            </div>
          )
        },
        size: 30,
        minSize: 30,
        maxSize: 70,
      })
    }

    let users = userList

    if (accountLeadUser && !accountLeadUser.isSuperAdmin) {
      users = users.filter((user) => user._id !== accountLeadUser._id)
      users.unshift(accountLeadUser)
    }

    return (
      <Table
        id="bu-users-table"
        columns={columns}
        data={users}
        onSelect={hasEditUserAccess ? setUsersToEdit : null}
        nonDeletableEntities={accountLeadUser?._id}
        className="custom-height__400"
      />
    )
  }, [
    JSON.stringify(userList),
    assignUsersReadAccess,
    businessUnit.accountLead,
  ])

  // const hasNamingConventionsEditAccess = useAccess({
  //   feature: PERMISSIONS.NAMING_CONVENTIONS,
  //   type: PERMISSION_TYPES.EDIT,
  // })
  // const hasNamingConventionsReadAccess = useAccess({
  //   feature: PERMISSIONS.NAMING_CONVENTIONS,
  //   type: PERMISSION_TYPES.READ,
  // })
  // const hasOfflineDataReadAccess = useAccess({
  //   feature: PERMISSIONS.OFFLINE_DATA,
  //   type: PERMISSION_TYPES.READ,
  // })
  const hasCampaignExclusionsReadAccess = useAccess({
    feature: PERMISSIONS.CAMPAIGN_EXCLUSIONS,
    type: PERMISSION_TYPES.READ,
  })
  // const hasConversionGroupsReadAccess = useAccess({
  //   feature: PERMISSIONS.CONVERSION_GROUPS,
  //   type: PERMISSION_TYPES.READ,
  // })
  const hasAccountCreateAccess = useAccess({
    feature: PERMISSIONS.ACCOUNT_DATA_FORM,
    type: PERMISSION_TYPES.CREATE,
  })

  const isAlertThresholdsLinked =
    company.links?.alertThresholds?.[businessUnit._id]

  const accountsToShow = structuredClone(
    businessUnit.accounts.filter((account) => account.company) || []
  )

  // Used for accounts dropdown, these don't change based on business unit's accounts.
  const businessUnitAccountsOptions = useMemo(() => {
    // Ensure that account hasn't been removed in the meanwhile
    let accountsFromParentOrEditedOnes
    if (company.accounts && company.accounts.length > 0) {
      accountsFromParentOrEditedOnes = company.accounts
    } else {
      accountsFromParentOrEditedOnes = defaultCompany.accounts
    }
    return [...accountsFromParentOrEditedOnes, ...businessUnit.accounts].filter(
      ({ company }) => !!company
    )
  }, [JSON.stringify(company.accounts)])

  const assignableBUAccounts = businessUnitAccountsOptions.filter(
    (objA) => !accounts.some((objB) => objA._id === objB._id)
  )

  /** Edit fields */
  const editField = (fieldName, fieldValue) => {
    setDirty(true)
    if (fieldName.indexOf('.') > -1) {
      const [field, subField] = fieldName.split('.')
      const fieldObj = { ...businessUnit[field], [subField]: fieldValue }
      setBusinessUnit({ [field]: fieldObj })
      const errorObj = { ...errors[field], [subField]: null }
      setErrors({ ...errors, [field]: errorObj })
    } else {
      setBusinessUnit({
        [fieldName]: fieldValue,
      })
      setErrors({ ...errors, [fieldName]: null })
    }
  }

  const editKpis = (updatedBu, field) => {
    setErrors({ ...errors, [field]: null })
    setBusinessUnit(updatedBu)
  }

  const onChangeInactiveCampaignsFlag = async (value) => {
    const newValue = await changeInactiveCampaignsFlag(businessUnit._id, value)
    setBusinessUnit({
      budgetPacingInactiveCampaigns: newValue,
    })
  }

  // const onChangeInactiveCampaignsForComparionFlag = async (value) => {
  //   const newValue = await changeInactiveCampaignsForComparisonFlag(
  //     businessUnit._id,
  //     value
  //   )
  //   setBusinessUnit({
  //     campaignComparisonInactiveCampaigns: newValue,
  //   })
  // }

  const checkValidEmail = (email) => {
    if (!isValidEmail(email)) {
      setErrors({ ...errors, email: 'Invalid email format' })
    }
  }
  const checkPhone = (phone) => {
    if (!isValidPhoneNumber(phone)) {
      setErrors({ ...errors, phone: 'Invalid phone number.' })
    }
  }

  const checkForModals = async () => {
    // Validate

    // eslint-disable-next-line no-unused-vars
    const { _id, ...fieldsToValidate } = businessUnit

    let [isValid, errors] = validate(ERROR_MAP, fieldsToValidate)

    const nameExists = await checkExistingName({ _id, name: businessUnit.name })
    if (nameExists) {
      isValid = false
      errors.name = 'Business Unit name already exists'
    } else {
      errors.name ??= null
    }

    const buIdExists = await checkExistingClientId({
      _id,
      clientId: businessUnit.clientId,
    })
    if (buIdExists) {
      isValid = false
      errors.clientId = 'Business Unit Id already exists.'
    } else {
      errors.clientId ??= null
    }

    if (businessUnit.offlineData?.length) {
      let isValidOfflineData = true
      const offlineDataErrors = {}

      // Filter out offline data without a filename
      businessUnit.offlineData = businessUnit.offlineData.filter(
        ({ filename }) => !!filename
      )

      businessUnit.offlineData.forEach((data) => {
        const [isValid, errors] = validateOfflineData(data)
        if (!isValid) {
          isValidOfflineData = false
          offlineDataErrors[data.filename] = errors
        }
      })

      if (!isValidOfflineData) {
        isValid = false
        if (!errors) {
          errors = {}
        }
        errors.offlineData = offlineDataErrors
      }
    }

    const [isValidFiscalPeriod, fiscalErrors] = getFiscalErrors(
      businessUnit.tableauConfig
    )

    if (!isValidFiscalPeriod) {
      isValid = false
      errors = Object.assign(errors || {}, {
        fiscalErrors,
      })
    }

    if (!isValid) {
      setLoading(false)
      return setErrors(errors)
    }
    if (
      checkForChanges(
        businessUnit.namingConventions,
        initialConventions.current
      )
    ) {
      setConventionNameModal(true)
      return
    }

    if (
      shouldDisplayOfflineDataDifferenceModal(
        businessUnit.offlineData,
        initialOfflineData.current
      )
    ) {
      setOfflineDataModal(true)
      return
    }
    onSave(errors)
  }

  const onSave = (errorsState) => {
    if (Object.keys(errorsState?.fiscalErrors || {}).length) {
      return
    }
    setLoading(true)

    let newCompanyAccounts = structuredClone(company.accounts)
    if (pendingBusinessUnitData.accounts) {
      const removedAccounts = objectDifference(
        defaultBusinessUnitData.accounts,
        businessUnit.accounts,
        'externalAccountId'
      )

      // Business Unit Accounts have no place on the client
      newCompanyAccounts = newCompanyAccounts.filter(
        ({ externalAccountId }) =>
          !businessUnit.accounts.some(
            (acc) => acc.externalAccountId === externalAccountId
          )
      )

      // If accounts were removed from the business unit, we add them back to the client
      if (removedAccounts?.length) {
        newCompanyAccounts.push(...removedAccounts)
      }
    }

    // Commit local business unit changes to the client storage
    businessUnitData.setBusinessUnits({
      [businessUnit._id]: pendingBusinessUnitData,
    })

    // Commit local user changes to the client storage
    usersData.setClientUsers(
      { [businessUnit._id]: pendingBusinessUnitUsers },
      true
    )

    setCompany({
      accounts: newCompanyAccounts,
    })

    setDirty(false)
    setTimeout(() => {
      navigate(baseClientUrl, {
        state: { BYPASS_LEAVE_CONFIRM: true },
      })
    }, 500)
  }

  const renderAccountsSection = () => {
    const columns = [
      {
        header: ' ',
        cell: (cell) => {
          const { key, externalAccountId } = cell.row.original
          if (readOnly) {
            return null
          }
          return (
            <div className="table__actions align-center">
              <Link
                className="table__edit "
                to={`${baseClientUrl}/account/${key || externalAccountId}`}
              />
            </div>
          )
        },
        size: 30,
        maxSize: 50,
      },
      {
        header: 'Account',
        cell: ({ row: { original: row } }) => {
          const accountType =
            ACCOUNT_TYPES.find((account) => account.id === row.type)?.id ||
            row.type
          return (
            <div className="align-row center bu__account__name">
              <div className="table__icons">
                <AccountIcon accountType={accountType} />
              </div>
              {row.name}
            </div>
          )
        },
        accessorFn: (row) => {
          const accountType =
            ACCOUNT_TYPES.find((account) => account.id === row.type)?.id ||
            row.type
          return { publisher: accountType, name: row.name }
        },
        textAlign: 'left',
        cellTextAlign: 'left',
        size: 360,
      },
      {
        id: 'bu-accountId',
        header: 'Account ID',
        accessorFn: (row) => row.externalAccountId,
        textAlign: 'left',
        cellTextAlign: 'left',
        size: 120,
      },
      {
        header: 'Unique MCC ID',
        cell: (cell) => {
          const { type } = cell.row.original
          const hasMCCId = googleMccId.length > 0 && type === GOOGLE
          return hasMCCId ? 'Yes' : 'No'
        },
        textAlign: 'left',
        cellTextAlign: 'left',
        size: 80,
      },
      {
        header: 'Users',
        accessorFn: (row) => {
          const usersInAccount = allUsers?.filter((user) =>
            user?.clients.find((client) => {
              return client?.accounts.find((account) => {
                return account === row._id
              })
            })
          )
          return usersInAccount.length || '0'
        },
        tooltip: (row) => {
          const usersInAccount = allUsers?.filter((user) =>
            user?.clients.find((client) => {
              return client?.accounts.find((account) => {
                return account === row._id
              })
            })
          )
          return getTooltipList(
            `Users from ${businessUnit.name}`,
            usersInAccount.map((item) => item.name)
          )
        },
        textAlign: 'center',
        cellTextAlign: 'left',
        size: 50,
        maxSize: 80,
        sortType: 'number',
      },
      {
        header: 'Date Added',
        id: 'createdAt',
        accessorFn: (row) =>
          format(new Date(row.createdAt || Date.now()), DEFAULT_DATE_FORMAT),
        size: 60,
        sortType: 'date',
      },
      {
        header: 'Status',
        id: 'status',
        cell: (cell) => {
          return ENTITY_STATUS_LABEL[cell.row.original.active]
        },
        textAlign: 'center',
        accessorFn: (row) => (row.active ? 1 : 0),
        size: 80,
        maxSize: 80,
        sortType: 'boolean',
      },
    ]

    return (
      <Table
        id="bu-accounts-table"
        columns={columns}
        data={accountsToShow}
        height={350}
        onSelect={(e) => setAccountsToEdit(e)}
        initialState={{ sortBy: [{ id: 'createdAt', desc: false }] }}
      />
    )
  }

  const renderAssignAccountsModal = () => {
    return (
      <Modal
        className="bu__assign-modal"
        contentSeparator
        heading="Assign Accounts"
        opened={!!showAssignAccountsModal}
        rightAlignButtons
        button={
          <Button
            value="Confirm"
            disabled={!assignAccounts.length}
            onClick={() => {
              navigate(`${baseClientUrl}/business-unit/${idParam}/account`, {
                state: {
                  accounts: assignAccounts,
                },
              })
            }}
          />
        }
        buttonSecondary={
          <Button
            value="Cancel"
            secondaryGray
            onClick={() => {
              setAccounts(businessUnit.accounts)
              setAssignAccounts([])
              setShowAssignAccountsModal(false)
            }}
          />
        }
      >
        <p>Select accounts that have been linked to the client.</p>
        <Dropdown
          defaultOptionText="Select Accounts"
          selectedItems={assignAccounts.map(
            ({ externalAccountId }) => externalAccountId
          )}
          selectAll={true}
          multiSelect
          options={assignableBUAccounts.map((account) => ({
            type: account.type,
            label: account.name,
            value: account.externalAccountId,
            externalAccountId: account.externalAccountId,
          }))}
          label="Account"
          className="label-placement--above input-wrapper--uppercase"
          onChange={(accounts) => {
            setAssignAccounts(
              assignableBUAccounts
                .filter(({ externalAccountId }) =>
                  accounts.includes(externalAccountId)
                )
                .map((account) => ({
                  ...account,
                  company: businessUnit._id,
                }))
            )
          }}
          optionRenderer={(option, selectedAccounts) =>
            accountsOptionRenderer(option, selectedAccounts)
          }
          error={errors.general}
        />
        <RemovableKpiList
          kpis={assignAccounts}
          onRemove={(removeAccount) => {
            setAssignAccounts(
              assignAccounts.filter(
                (account) => account._id !== removeAccount._id
              )
            )
          }}
        />
      </Modal>
    )
  }

  /** Get all kpis for the selected accounts */
  useEffect(() => {
    if (isViewMode || !businessUnit.accounts?.length) {
      setKpis([])
      return
    }
    const getSpecialMetrics = async (type) => {
      setLoadingKpis((loadingKPIs) => loadingKPIs + 1)
      try {
        const results = await getCustomConversionsForAccounts(
          businessUnit.accounts,
          type
        )
        if (!results.length) {
          return []
        }
        return results
      } catch (e) {
        console.error(e)
        return []
      } finally {
        setLoadingKpis((loadingKPIs) => loadingKPIs - 1)
      }
    }

    const providers = [
      GOOGLE,
      FACEBOOK,
      MICROSOFT,
      TRADE_DESK,
      LINKED_IN,
      TIKTOK,
      GOOGLE_ANALYTICS,
      SA360,
      GOOGLE_CAMPAIGN_MANAGER,
      APPLE,
      TWITTER,
      AMAZON_DSP,
      AMAZON_ADVERTISING,
      DV360,
      REDDIT,
      SNAPCHAT,
      PINTEREST,
    ]

    const setSpecialMetrics = async () => {
      let data = []
      for (const provider of providers) {
        const res = await getSpecialMetrics(provider)
        data = [...data, ...res]
      }

      setKpis(data)
      setCustomConversionsForAccounts(dispatch, data)
    }

    setSpecialMetrics().catch(console.error)
  }, [JSON.stringify(businessUnit.accounts), isViewMode])

  const clearErrorOnField = (field) => {
    if (errors[field]) {
      setErrors({ ...errors, [field]: null })
    }
  }

  const triggerRestoreValidation = () => {
    if (!businessUnit.clientId || businessUnit.new) {
      return
    }
    checkForRestore(businessUnit.clientId)
      .then((restoreInfo) => {
        if (restoreInfo.canRestore) {
          setErrors({ clientId: true })
          setRestoreInfo(restoreInfo)
          return
        }
        clearErrorOnField('clientId')
        if (restoreInfo) {
          setRestoreInfo(null)
        }
      })
      .catch(console.error)
  }

  const closeRestoreNotification = () => setRestoreInfo(null)

  const cancelRestorePrompt = () => {
    clearErrorOnField('clientId')
    closeRestoreNotification()
  }

  const handleRestore = () => {
    return new Promise((resolve) => {
      setDirty(false)
      restoreClient(resolve)
    })
  }

  if (!businessUnit) {
    return <Loader />
  }

  const accountsOptionRenderer = (option, selectedAccounts) => {
    return (
      <div className="align-row account-dropdown">
        <CheckboxNoHooks
          icon={<AccountIcon accountType={option.type} />}
          label={option.label}
          isChecked={selectedAccounts.indexOf(option.value) > -1}
          hint={
            <span data-testid="hint-element">{option.externalAccountId}</span>
          }
        />
      </div>
    )
  }

  /** Render method */
  return (
    <div
      className="add-edit-companies"
      data-cy={
        businessUnit.new ? 'add-business-unit-page' : 'edit-business-unit-page'
      }
    >
      <RestoreNotification
        triggeredForBusinessUnit
        visible={!!restoreInfo}
        restoreInfo={restoreInfo}
        onCancel={cancelRestorePrompt}
        onClose={closeRestoreNotification}
        onRestore={handleRestore}
        onArchivedFilter={seeArchivedClients}
      />
      {itemToDelete && (
        <Modal
          contentSeparator
          rightAlignButtons
          opened={!!itemToDelete}
          button={
            <Button
              green
              value="Archive"
              onClick={() => {
                setCompany({
                  accounts: company.accounts.map((account) =>
                    account.company === businessUnit._id
                      ? {
                          ...account,
                          company: company._id,
                          businessUnitName: undefined,
                          businessUnitId: undefined,
                          changed: true,
                        }
                      : account
                  ),
                })
                businessUnitData.setBusinessUnits({
                  [businessUnit._id]: {
                    _id: businessUnit._id,
                    clientId: businessUnit.clientId,
                    active: false,
                    deleted: true,
                  },
                })
                setDirty(false)
                navigate(baseClientUrl, {
                  replace: true,
                  state: { BYPASS_LEAVE_CONFIRM: true },
                })
                setItemToDelete(null)
              }}
            />
          }
          buttonSecondary={
            <Button
              value="Cancel"
              onClick={() => setItemToDelete(null)}
              secondaryGray
            />
          }
          heading="Archive"
          className="alert-categories__modal"
        >
          <p>Please confirm you want to archive this business unit:</p>
          <b>{businessUnit.name}</b>
          <p>Note: an archived business unit and its data can be restored.</p>
        </Modal>
      )}
      <SaveConventionModal
        open={conventionNameModal}
        onConfirm={() => {
          onSave()
          setConventionNameModal(false)
        }}
        onCancel={() => {
          editField('conventionNames', initialConventions.current)
          setConventionNameModal(false)
        }}
      />
      <SaveOfflineDataModal
        open={offlineDataModal}
        onConfirm={() => {
          onSave()
          setOfflineDataModal(false)
        }}
        onCancel={() => {
          editField('offlineData', initialOfflineData.current)
          setOfflineDataModal(false)
        }}
      />
      <Modal
        opened={!!deleteUsersModal}
        button={
          <Button
            value="Ok"
            onClick={() => {
              setDeleteUsersModal(null)
            }}
            onEnterKeyPressed={() => {
              setDeleteUsersModal(null)
            }}
          />
        }
        heading={`Unable to delete ${deleteUsersModal} as it is a default approver on ${businessUnit.name}.`}
        className="alert-categories__modal"
      />
      {!isEmpty(thresholdModalAlert) && (
        <AlertsThresholdModal
          alert={thresholdModalAlert}
          onCancel={() => {
            setThresholdModalAlert({})
          }}
          onSave={(alertThresholds) => {
            editField('alertThresholds', alertThresholds)
            setThresholdModalAlert({})
          }}
          company={businessUnit}
          // isAlertThresholdsLinked={isAlertThresholdsLinked}
          parentClient={company}
          // copyFromCompany={copyFromCompany}
          defaultClient={defaultBusinessUnitData}
        />
      )}
      {businessUnitEditAccess ? (
        <form onSubmit={checkForModals} className="form companies">
          {dirty && <LeaveConfirmModal />}
          <div className="align-row form__section__body add-edit-companies__form">
            <div className="form__section__body__half-width-section">
              <InputText
                data-cy="business-unit-name-field"
                placeholder={'Business Unit Name'}
                label="Enter Name"
                labelTop
                value={businessUnit.name}
                onChange={(val) => {
                  editField('name', val)
                }}
                error={errors.name}
                disabled={loading}
              />
              <div className="align-row">
                <InputText
                  data-cy="business-unit-primary-contact-field"
                  placeholder="Enter Name"
                  label="Primary Contact"
                  labelTop
                  value={businessUnit.contact?.name}
                  onChange={(val) => editField('contact.name', val)}
                  error={errors.contact?.name}
                  disabled={loading}
                  className="input-half-width "
                />
                <InputText
                  data-cy="business-unit-contact-phone-field"
                  placeholder="Enter Phone"
                  label="Primary Contact Phone"
                  labelTop
                  value={businessUnit.contact?.primaryContactPhone}
                  onChange={(val) =>
                    editField('contact.primaryContactPhone', val)
                  }
                  error={errors.contact?.primaryContactPhone}
                  disabled={loading}
                  className="input-half-width right-half-input"
                  blur={() => checkPhone(businessUnit.primaryContactPhone)}
                />
              </div>
              <InputText
                data-cy="business-unit-primary-contact-email-field"
                placeholder="Enter Email"
                label="Primary Contact Email"
                labelTop
                value={businessUnit.contact?.email}
                onChange={(val) => editField('contact.email', val)}
                error={errors.contact?.email}
                disabled={loading}
                blur={() => checkValidEmail(businessUnit.email)}
              />
              <Dropdown
                data-cy="business-unit-default-approver-field"
                defaultOptionText="Default Approver"
                defaultState={businessUnit.defaultApprover || ''}
                options={(allowedApprovers || []).map((user) => ({
                  value: user._id,
                  label: user.name,
                  disabled: user._id === businessUnit.backupApprover,
                }))}
                label="Default Approver"
                labelTop
                onChange={(value) => editField('defaultApprover', value)}
                error={errors.defaultApprover || errors.general}
              />
              <Dropdown
                data-cy="business-unit-backup-approver-field"
                defaultOptionText="Backup Approver"
                defaultState={businessUnit.backupApprover || ''}
                deselectLabel="Backup Approver"
                options={(allowedApprovers || []).map((user) => ({
                  value: user._id,
                  label: user.name,
                  disabled: user._id === businessUnit.defaultApprover,
                }))}
                onChange={(value) => editField('backupApprover', value)}
                label="Backup Approver"
                labelTop
                error={errors.backupApprover || errors.general}
              />

              <CheckboxNoHooks
                data-cy={'account-outage-notifications'}
                label={'Missing Access Slack Notifications'}
                isChecked={businessUnit.hasOutageNotifications}
                onChange={() => {
                  editField(
                    'hasOutageNotifications',
                    !businessUnit.hasOutageNotifications
                  )
                }}
                hint={
                  <span>
                    <div>
                      If checked, Client will receive Account Outage Slack
                      notifications
                    </div>
                  </span>
                }
                className="checkbox-first "
              />
            </div>
            <div className="form__section__body__half-width-section right-side ">
              <InputText
                label="Business Unit ID"
                labelTop
                data-cy="business-unit-unit-id-field"
                placeholder="Enter Business Unit ID"
                value={businessUnit.clientId}
                onChange={(val) => {
                  editField('clientId', val)
                }}
                blur={triggerRestoreValidation}
                error={errors.clientId}
                disabled={loading}
              />

              <Dropdown
                data-cy="business-unit-status-field"
                defaultOptionText="Status"
                defaultState={businessUnit.active}
                options={ENTITY_STATUS_OPTIONS}
                label="Status"
                labelTop
                disabled={!company.active}
                onChange={(value) => editField('active', value)}
                error={errors.status || errors.general}
              />

              {/* Currency */}
              <Dropdown
                data-cy="create-edit-client-form-currency"
                defaultOptionText="Currency"
                label="Currency"
                defaultState={
                  businessUnit.primaryCurrency
                    ? businessUnit.primaryCurrency
                    : ''
                }
                options={Object.entries(CURRENCY_LABELS).map(
                  ([value, label]) => ({
                    value,
                    label,
                  })
                )}
                disabled={loading}
                className="input-wrapper--uppercase"
                onChange={(primaryCurrency) =>
                  editField('primaryCurrency', primaryCurrency)
                }
              />

              <AccountLead
                defaultState={businessUnit.accountLead}
                onChange={(accountLead) =>
                  editField('accountLead', accountLead)
                }
              />

              <InputText
                data-cy="business-unit-slack-channel-field"
                placeholder="Enter Slack Channel Name"
                value={businessUnit.slack}
                onChange={(val) => editField('slack', val)}
                error={errors.slack}
                label="Slack Channel"
                disabled={loading}
              />
            </div>
          </div>

          <KpiSection
            isBusinessUnit
            data-cy="business-unit-kpis"
            company={businessUnit}
            onChange={editKpis}
            errors={errors}
            setErrors={setErrors}
            kpis={kpis}
            loadingKpis={loadingKpis}
          />
        </form>
      ) : (
        <div className="client-view">
          <div className="align-row form__section__body">
            <div className="form__section__body__half-width-section">
              <LabelWrapper
                className="margin-bottom-20"
                label="Business Unit Name"
              >
                {businessUnit.name}
              </LabelWrapper>

              <div className="align-row margin-bottom-20">
                <LabelWrapper label="Primary Contact">
                  {businessUnit.contact.name}
                </LabelWrapper>
                <LabelWrapper label="Contact Phone">
                  {businessUnit.contact.primaryContactPhone}
                </LabelWrapper>
              </div>
              <LabelWrapper
                className="margin-bottom-20"
                label="Primary Contact Email"
              >
                {businessUnit.contact.email}
              </LabelWrapper>
              <LabelWrapper
                className="margin-bottom-20"
                label="Default Approver"
              >
                {businessUnit.defaultApprover}
              </LabelWrapper>
              <LabelWrapper label="Backup Approver">
                {businessUnit.backupApprover}
              </LabelWrapper>
            </div>

            <div className="form__section__body__half-width-section right-side">
              {/* ClientId */}
              <LabelWrapper
                className="margin-bottom-20"
                label="Business Unit ID"
              >
                {businessUnit.clientId}
              </LabelWrapper>
              {/* Status */}
              <LabelWrapper className="margin-bottom-20" label="Status">
                {ENTITY_STATUS_LABEL[businessUnit.active]}
              </LabelWrapper>
              <LabelWrapper label="Account Lead" className="margin-bottom-20">
                {businessUnit.accountLead}
              </LabelWrapper>
              <LabelWrapper label="Slack Channel" className="margin-bottom-20">
                {businessUnit.slack}
              </LabelWrapper>
            </div>
          </div>

          <KpiSection
            readOnly
            isBusinessUnit
            company={businessUnit}
            kpis={kpis}
          />
        </div>
      )}
      {/* {!businessUnit.isNew && (
        <ReportingSection
          data-cy="business-unit-reporting"
          company={businessUnit}
          setCompany={(result) => setBusinessUnit({ result })}
          openedSubSection={openedSubSection}
          setOpenedSubSection={setOpenedSubSection}
          isViewMode={isViewMode}
        />
      )} */}
      {alertsAccess && (
        <AlertsSection
          dispatch={dispatch}
          company={businessUnit}
          parentClient={company}
          editing={!businessUnit.new}
          user={userData}
          isViewMode={isViewMode}
          isAlertThresholdsLinked={isAlertThresholdsLinked}
          openModal={setThresholdModalAlert}
          links={company.links?.alerts || {}}
          onChangeLinks={(alerts) => {
            // if (Object.values(alerts).every((isLinked) => !isLinked)) {
            //   linkClientSections({
            //     alerts,
            //   })
            //   return
            // }
            checkForSingleClientAlerts([company._id]).then((canContinue) => {
              if (canContinue) {
                linkClientSections({
                  alerts,
                })
              }
            })
          }}
          simulations={simulations}
        />
      )}
      {/** Alert Thresholds */}
      {alertThresholdAccess ? (
        <AlertThresholdSection
          company={businessUnit}
          parentClient={company}
          openedSubSection={openedSubSection.thresholds}
          setOpenedSubSection={(thresholds) =>
            setOpenedSubSection({ ...openedSubSection, thresholds })
          }
          alertThresholds={alertThresholds}
          loading={alertThresholdsLoading}
          setCompany={(bu) => {
            setDirty(true)
            setBusinessUnit(bu)
          }}
          links={company.links?.alertThresholds || {}}
          onChangeLinks={(alertThresholds) =>
            linkClientSections({
              alertThresholds,
            })
          }
          simulations={simulations}
        />
      ) : null}
      <Spacer />
      <CollapsibleSection
        header="Accounts"
        id="bu-accounts-section"
        defaultCollapsed={isAccountsPanelCollapsed}
        onCollapseListener={(value) => setIsAccountsPanelCollapsed(!value)}
        extras={
          <div className="display-flex">
            {accountsToEdit.length > 0 ? (
              <Button
                style={{ marginRight: '16px' }}
                value="Edit Selected"
                onClick={() =>
                  navigate(
                    `${baseClientUrl}/business-unit/${idParam}/account`,
                    {
                      state: {
                        accounts: accountsToEdit,
                      },
                    }
                  )
                }
                className="space-right fixed-height bu__assign__button"
              />
            ) : null}
            {assignableBUAccounts.length > 0 ? (
              <Button
                secondary
                disabled={isAccountsPanelCollapsed}
                value={
                  <div className="action-button">
                    <ArrowsIcon className="bu__assign__icon" />
                    <div>Assign Accounts</div>
                  </div>
                }
                onClick={() => {
                  setShowAssignAccountsModal(true)
                }}
                className="space-right fixed-height bu__assign__button"
              />
            ) : null}
            {hasAccountCreateAccess && (
              <Button
                value={
                  <div className="action-button">
                    <BlueLinkIcon />
                    <div>Link Accounts</div>
                  </div>
                }
                onClick={() => {
                  setAccountsToEdit([])
                  navigate(`${baseClientUrl}/business-unit/${idParam}/account`)
                }}
                secondary
                className="fixed-height bu__assign__button"
                disabled={isAccountsPanelCollapsed}
              />
            )}
          </div>
        }
      >
        {renderAccountsSection()}
      </CollapsibleSection>
      <div data-cy="bu-accounts-section">
        {showAssignAccountsModal && renderAssignAccountsModal()}
      </div>
      {/** Assign users to BU */}
      {assignUsersReadAccess ? (
        <>
          <Spacer />
          <CollapsibleSection
            onCollapseListener={setOpenedSubSection}
            defaultCollapsed={!openedSubSection}
            header={'Users'}
            extras={
              <div className="align-row align-right">
                {usersToEdit.length > 0 &&
                  !!userList?.length &&
                  assignUsersEditAccess && (
                    <>
                      <Button
                        value="Remove Selected"
                        onClick={() => {
                          const found = usersToEdit.find((user) => {
                            return (
                              `${businessUnit.defaultApprover}` ===
                              `${user._id}`
                            )
                          })
                          if (found) {
                            setDeleteUsersModal(found.name)
                          } else {
                            const userChanges = {}
                            usersToEdit.forEach((user) => {
                              userChanges[user._id] = false
                            })
                            setUsers(userChanges, true)
                            setDirty(true)
                          }
                        }}
                        secondaryRed
                        className="fixed-height"
                      />
                    </>
                  )}
                {assignUserModal && (
                  <AssignUserModal
                    open={assignUserModal}
                    users={usersInClient.filter((user) => !users[user._id])}
                    onClose={() => {
                      setAssignUsersModal(false)
                    }}
                    title="Assign Users to Business Unit"
                    onSave={(users) => {
                      const userChanges = {}
                      users.forEach((user) => {
                        userChanges[user._id] = true
                      })
                      setUsers(userChanges, true)
                      setDirty(true)
                      setAssignUsersModal(false)
                    }}
                  />
                )}
                {assignUsersEditAccess ? (
                  <Button
                    value={
                      <div className="action-button">
                        <PlusIcon />
                        Assign Users
                      </div>
                    }
                    onClick={() => {
                      setAssignUsersModal(true)
                    }}
                    secondary
                    className={`fixed-height`}
                  />
                ) : null}
              </div>
            }
          >
            {usersTable}
          </CollapsibleSection>
        </>
      ) : null}
      {/* {hasReportViewAccess ? (
        <ReportsSection
          reportings={businessUnit.reportings}
          company={businessUnit}
          setCompany={(result) => editField('reportings', result.reportings)}
          users={allUsers}
        />
      ) : null} */}
      {/* {hasNamingConventionsReadAccess && (
        <NamingConventionsSection
          client={businessUnit}
          parentClient={company}
          onChangeConventions={(conventions) =>
            editField('namingConventions', conventions)
          }
          isViewMode={!hasNamingConventionsEditAccess}
          links={company.links?.namingConventions || {}}
          onChangeLinks={(namingConventions) =>
            linkClientSections({
              namingConventions,
            })
          }
          setOpenedSubSection={setOpenedSubSection}
          openedSubSection={openedSubSection}
        />
      )} */}
      {/* {!businessUnit.new && hasOfflineDataReadAccess && (
        <OfflineDataSection
          clientId={businessUnit._id}
          setErrors={(offlineDataErrors) =>
            setErrors({ ...errors, offlineData: offlineDataErrors })
          }
          errors={errors.offlineData}
          isViewMode={!userData.isSuperAdmin}
        />
      )} */}
      {!businessUnit?.new && hasCampaignExclusionsReadAccess && (
        <CampaignExclusionsSection
          company={businessUnit}
          businessUnitClientId={businessUnit.clientId}
          isViewMode={!userData.isSuperAdmin}
          links={company.links?.campaignExclusions || {}}
          onChangeLinks={(campaignExclusions) =>
            linkClientSections({
              campaignExclusions,
            })
          }
        />
      )}
      {budgetPacingReadAccess && !businessUnit.new && (
        <BudgetPacingSection
          currentCompany={businessUnit}
          companyId={businessUnit._id}
          isViewMode={isViewMode}
          inactiveCampaignsFlag={businessUnit.budgetPacingInactiveCampaigns}
          onChangeInactiveCampaignsFlag={onChangeInactiveCampaignsFlag}
        />
      )}
      {/* {!businessUnit?.new && hasConversionGroupsReadAccess && (
        <ConversionGroupingSection
          isViewMode={!userData.isSuperAdmin}
          currentCompany={businessUnit}
          kpis={kpis}
          loadingKpis={loadingKpis}
          offlineData={businessUnit.offlineData}
        />
      )} */}
      {/* {!currentBusinessUnit.new && (
        <ReportingViewsSection company={businessUnit} />
      )} */}
      {/* {!company.new && (
        <CalculatedMetricsSection
          company={businessUnit}
          setCompany={(result) =>
            editField('calculatedMetrics', result.calculatedMetrics)
          }
          viewMode={isViewMode}
          calculatedMetrics={businessUnit.calculatedMetrics || []}
          users={userList}
        />
      )} */}
      {/* {!businessUnit.new && (
        <CampaignComparisonSection
          company={businessUnit}
          inactiveCampaignsFlag={
            businessUnit.campaignComparisonInactiveCampaigns
          }
          onChangeInactiveCampaignsFlag={
            onChangeInactiveCampaignsForComparionFlag
          }
        />
      )} */}
      <StickyFooter
        buttons={[
          {
            value: 'Reset Cached Data',
            onClick: resetData,
            disabled: loading,
            renderCondition: !businessUnit.new && userData.isSuperAdmin,
          },
          {
            value: 'Save',
            onClick: checkForModals,
            disabled: loading,
            className: 'space-right',
          },
          {
            value: 'Cancel',
            onClick: () => navigate(baseClientUrl),
            disabled: loading,
            secondaryGray: true,
          },
          {
            value: 'Archive',
            onClick: () => {
              setItemToDelete(businessUnit._id)
            },
            type: 'secondaryRed',
            disabled: loading || businessUnit.active,
            className: 'space-right',
            renderCondition: !businessUnit.new && businessUnitDeleteAccess,
          },
        ]}
      >
        {/* <Button
          disabled={
            !Object.values(copySettings)?.some((el) => el?.currentIndex >= 0)
          }
          compact
          secondaryRed
          onClick={() => {
            undoCopySettings(dispatch)
          }}
          value={
            <div className="align-row center gap-4">
              <UndoIcon width="16" height="16" />
              <span>Undo</span>
            </div>
          }
          style={{ marginLeft: '20px' }}
        /> */}
      </StickyFooter>
    </div>
  )
}

const ERROR_MAP = {
  name: FIELD_TYPES.REQUIRED,
  clientId: [FIELD_TYPES.REQUIRED, FIELD_TYPES.CLIENT_ID],
  'contact.email': [FIELD_TYPES.EMAIL, FIELD_TYPES.REQUIRED],
  'contact.primaryContactPhone': [FIELD_TYPES.PHONE, FIELD_TYPES.REQUIRED],
  'contact.name': FIELD_TYPES.REQUIRED,
  active: [FIELD_TYPES.BOOLEAN, FIELD_TYPES.REQUIRED],
  defaultApprover: FIELD_TYPES.REQUIRED,
}

export default CreateEditBusinessUnit
